<div class="plan-basic" [ngClass]="themeService.currentPalette">
  <div class="plan-basic-content" *ngIf="plans?.length">
    <carousel class="carru" [ngClass]="{'single-carousel': plans.length === 1}" [itemsPerSlide]="isLargeScreen ? 2 : 1">
      <slide *ngFor="let plan of plans" class="slider" [ngClass]="{'single-plan': plans.length === 1}">
        <div class="carousel-container">
          <div class="preview">
            <p>{{ plan.getName() }}</p>
            <img class="image-prod" [src]="plan.getImage()" alt="imagen planes">
            <h1 class="big-numbers">${{ plan.getPrice() }}</h1>
            <div class="description">
              <p #descripcionPlan>{{ plan.getDescription() }}</p>
            </div>
            <button (click)="onSelectPlan(plan)" class="custom-button">
              Seleccionar
            </button>
          </div>
        </div>
      </slide>
      <div class="carousel-controls" *ngIf="plans.length > 1">
        <button class="carousel-nav-left" (click)="prevSlide()"><mat-icon>arrow_back</mat-icon></button>
        <button class="carousel-nav-right" (click)="nextSlide()"><mat-icon>arrow_forward</mat-icon></button>
      </div>
    </carousel>
  </div>
</div>
